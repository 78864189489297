<template>
  <section id="about-medias">
    <v-img
      :aspect-ratio="ratio"
      :src="imgMedia"
      width="100%"
    >
      <v-container
        style="max-width: 1000px;"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <div class="co-w-full co-h40" />
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <land-title
              v-if="title"
              :title="title"
              class="text-uppercase"
              space="1"
            />
            <v-divider
              class="my-1"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-row>
              <v-col
                v-for="(item, i) in items"
                :key="i"
                cols="12"
                md="6"
              >
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <div
                      class="d-flex flex-column"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-card
                        class="d-flex flex-row py-3"
                        color="transparent"
                        flat
                        tile
                      >
                        <v-avatar
                          rounded="0"
                          size="28"
                        >
                          <v-img
                            :src="item.avatar"
                          />
                        </v-avatar>
                        <div
                          class="text-subtitle-1 blue-grey--text ml-4 co-flex-grow"
                          :style="{cursor: item.link ? 'pointer' : 'default'}"
                          @click="onItem(i, item)"
                        >
                          {{ item.label }}
                        </div>
                        <v-icon
                          :color="color"
                        >
                          mdi-chevron-right
                        </v-icon>
                      </v-card>
                      <v-divider
                        class="my-1"
                      />
                    </div>
                  </template>
                  <v-card
                    class="rounded pa-2 my-3"
                    color="white"
                    tile
                    flat
                  >
                    <v-img
                      width="160"
                      ratio="1"
                      :src="item.qrcode"
                    />
                  </v-card>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div class="co-w-full co-h40" />
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </section>
</template>

<script>
  import app from '@/api/co.app'
  import api from '@/api/co.api'
  import web from '@/api/web/co.web'

  export default {
    data () {
      return {
        title: '媒体矩阵',
        ratio: 2.4,
        imgMedia: app.pic.img_about_media_lg,
        color: 'blue-grey',
        content: {},
        media: {},
        items: []
      }
    },
    created () {
      this.getContent()
    },
    methods: {
      onItem (index, item) {
        if (item.link) {
          window.open(item.link, '_blank')
        }
      },
      getContent () {
        const me = this
        let params = null
        const content = {}
        const executed = function (res) {
          if (res.status) {
            const items = params.items
            for (const i in items) {
              const item = items[i]
              content[item.value] = {
                name: item.value,
                title: item.label,
                items: item.content.items || []
              }
            }

            me.content = content
            me.media = content.media || {}
            me.items = me.media.items
          }
        }

        params = web.dict.getParams({
          type: 'about',
          executed
        })
        api.httpx.getItems(params)
      }
    }
  }
</script>
